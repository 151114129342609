import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHeart,
  faStar,
  faEnvelope,
  faExclamationCircle,
  faGlobeAsia,
  faMapMarkerAlt,
  faChevronDown,
  faStickyNote,
  faLevelUpAlt,
  faLevelDownAlt,
  faUpload,
  faCaretDown,
  faTimes,
  faSave,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faArrowLeft,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faCog,
  faQuestionCircle,
  faCrown,
  faPlusSquare,
  faMinusSquare,
  faBars,
  faGripVertical,
  faArrowsAlt,
  faSortAlphaUp,
  faDotCircle,
  faMinusCircle,
  faChevronCircleUp,
  faChevronCircleDown,
  faClipboard
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHeart,
  faStar,
  faEnvelope,
  faExclamationCircle,
  faGlobeAsia,
  faMapMarkerAlt,
  faChevronDown,
  faStickyNote,
  faLevelUpAlt,
  faLevelDownAlt,
  faUpload,
  faCaretDown,
  faTimes,
  faSave,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faArrowLeft,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faCog,
  faQuestionCircle,
  faCrown,
  faPlusSquare,
  faMinusSquare,
  faBars,
  faGripVertical,
  faArrowsAlt,
  faSortAlphaUp,
  faDotCircle,
  faMinusCircle,
  faChevronCircleUp,
  faChevronCircleDown,
  faClipboard
)

Vue.component('VueFontawesome', FontAwesomeIcon)
