import { keyBy } from 'lodash'
import { getCurrency } from 'locale-currency'
import { getPreferences } from '@/misc/helpers'

export default {
  cachedRate: state => (currency, date) => {
    const ref = state.rateCache[currency]
    if (!ref) return undefined
    return ref[+date]
  },
  inferDisplayCurrency: () => getCurrency(navigator.language),
  currenciesById: state => keyBy(state.currencies, 'id'),
  getCurrencyById: (state, getters) => {
    const { currenciesById } = getters
    return id => currenciesById[id]
  },
  convert: (state, getters) => (amount, id, subunit) => {
    if (!subunit) {
      return amount
    }
    const currency = getters.getCurrencyById(id)
    if (currency.subunits && subunit in currency.subunits) {
      const { divisor = 1, preOffset = 0, postOffset = 0 } = currency.subunits[
        subunit
      ]
      return (amount + preOffset) / divisor + postOffset
    }
    return amount
  },
  convertToBase: (state, getters) => (amount, id, subunit) => {
    const currency = getters.getCurrencyById(id)
    if (!currency.subunits || !(subunit in currency.subunits)) {
      return amount
    }
    const { divisor = 1, preOffset = 0, postOffset = 0 } = currency.subunits[
      subunit
    ]
    return divisor * (amount - postOffset) - preOffset
  },
  defaultSubunits({ currencies }) {
    const store = {}
    currencies.forEach(currency => {
      store[currency.id] = null
      if ('subunits' in currency) {
        const defaultSubunit = Object.values(currency.subunits).find(
          ({ isDefault }) => isDefault
        )
        if (defaultSubunit) {
          store[currency.id] = defaultSubunit
        }
      }
    })
    return store
  },
  defaultSubunit: (state, getters) => {
    const { defaultSubunits } = getters
    return currencyId => defaultSubunits[currencyId]
  },
  activeSubunit: ({ activeCurrency, subunitPreferences }, getters) => {
    if (!activeCurrency) {
      return null
    }
    if (!activeCurrency.subunits) {
      return null
    }
    const { id } = activeCurrency
    if (
      id in subunitPreferences &&
      subunitPreferences[id] in activeCurrency.subunits
    ) {
      return activeCurrency.subunits[subunitPreferences[id]]
    }
    const defaultUnit = getters.defaultSubunit(activeCurrency.id)
    return defaultUnit || null
  },
  activeCurrencyWithSubunit: ({ activeCurrency }, getters) => {
    if (!activeCurrency) {
      return null
    }
    if (!getters.activeSubunit) {
      return activeCurrency.id
    }
    return `${activeCurrency.id}.${getters.activeSubunit.id}`
  },
  currencyFormatOptions: (state, { getCurrencyById }, rootState) => {
    const { maxSF, useDecimals } = getPreferences(rootState)
    return currencyWithSubunit => {
      const [currency, subunit] = currencyWithSubunit.split('.', 2)
      const currencyInfo = getCurrencyById(currency)
      let { format = null, minPrecision = 0, maxPrecision = 20 } = currencyInfo
      if (
        subunit &&
        currencyInfo.subunits &&
        subunit in currencyInfo.subunits
      ) {
        ;({
          format = format,
          minPrecision = minPrecision,
          maxPrecision = maxPrecision
        } = currencyInfo.subunits[subunit])
      }
      if (useDecimals || currencyInfo.type !== 'fiat') {
        return { currency, minPrecision, maxPrecision, format, maxSF }
      }
      // Turn off decimals on fiat currencies for people who don't want them
      return { currency, minPrecision: 0, maxPrecision: 0, format, maxSF }
    }
  }
}
