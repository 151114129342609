import { find } from 'lodash'
import distinctColors from 'distinct-colors'

export const assetClasses = [
  { id: 'cash', name: 'Cash' },
  { id: 'fixed', name: 'Fixed Income' },
  { id: 'equity', name: 'Shares' },
  { id: 'property', name: 'Real Estate' },
  { id: 'retirement', name: 'Retirement Savings' },
  { id: 'commodity', name: 'Commodities' },
  { id: 'vehicle', name: 'Vehicles' },
  { id: 'bullion', name: 'Bullion' },
  { id: 'store', name: 'Store Credit' },
  { id: 'custom', name: 'Custom' }
]

distinctColors({
  count: assetClasses.length,
  hueMin: 100,
  hueMax: 240,
  lightMin: 40
}).forEach((color, idx) => {
  assetClasses[idx].color = color.hex()
})

export const liabilityClasses = [
  { id: 'credit', name: 'Credit/Store Card' },
  { id: 'mortgage', name: 'Mortgage' },
  { id: 'loan', name: 'General Loan' },
  { id: 'student', name: 'Student Loan' },
  { id: 'margin', name: 'Margin Loan' },
  { id: 'planned', name: 'Planned Expense' },
  { id: 'unpaid', name: 'Unpaid Expense' },
  { id: 'tax', name: 'Tax' },
  { id: 'custom', name: 'Custom' }
]

distinctColors({
  count: liabilityClasses.length,
  hueMin: -40,
  hueMax: 95,
  lightMin: 35
}).forEach((color, idx) => {
  liabilityClasses[idx].color = color.hex()
})

export const OffsetAccountTypes = ['cash', 'custom']

export const OffsetableLoanTypes = ['mortgage', 'margin', 'custom']

function getType(id) {
  const assetType = find(assetClasses, type => type.id === id)
  if (assetType) return assetType
  const liabilityType = find(liabilityClasses, type => type.id === id)
  if (liabilityType) return liabilityType
  return undefined
}

let customColors = {}
export function regenerateCustomColors(customCategories) {
  customColors = {}
  if (!customCategories.length) {
    return
  }
  distinctColors({
    count: customCategories.length,
    hueMin: 250,
    hueMax: 300,
    lightMin: 35
  }).forEach((color, idx) => {
    customColors[customCategories[idx]] = color.hex()
  })
}

export function getTypeColor(id) {
  const type = getType(id)
  if (type) {
    return type.color
  }
  if (customColors[id]) {
    return customColors[id]
  }
  return '#33c6b4'
}

export function getTypeName(id) {
  const type = getType(id)
  if (type) {
    return type.name
  }
  return id
}
