import { find, cloneDeep, pick, sortBy } from 'lodash'
import { currencyWithSubunit } from '@/misc/helpers'
import dayjs from '@/dayjs'

export default {
  atFreePlanLimit: state => Object.values(state.entries).length >= 100,
  getEntryById: state => id => find(state.entries, entry => entry.id === id),
  getEntryByDate: (state, getters) => date => {
    const id = dayjs(date).format('YYYY-MM-DD')
    return getters.getEntryById(id)
  },
  totalEntries: state => Object.values(state.entries).length,
  newEntryForm: (state, getters, rootState, rootGetters) => {
    const categoryWeight = rootGetters['categories/categoryWeight']
    return (params = {}) => {
      const lastEntry = state.entries[0]
      const entryForm = {
        assets: [],
        liabilities: [],
        note: ''
      }
      if (params.date && dayjs(params.date).isValid()) {
        entryForm.date = dayjs(params.date)
          .startOf('day')
          .toDate()
      } else {
        const today = dayjs().startOf('day')
        entryForm.date = today.toDate()
      }
      if (lastEntry) {
        lastEntry.assets.forEach(asset => {
          const copiedAsset = cloneDeep(asset)
          copiedAsset.lastAmount = copiedAsset.amount
          copiedAsset.lastCurrency = currencyWithSubunit(
            copiedAsset.currency,
            copiedAsset.subunit
          )
          copiedAsset.amount = 0
          entryForm.assets.push(copiedAsset)
        })
        lastEntry.liabilities.forEach(liability => {
          const copiedLiability = cloneDeep(liability)
          copiedLiability.lastAmount = copiedLiability.amount
          copiedLiability.lastCurrency = currencyWithSubunit(
            copiedLiability.currency,
            copiedLiability.subunit
          )
          copiedLiability.amount = 0
          entryForm.liabilities.push(copiedLiability)
        })
      }
      entryForm.assets = sortBy(entryForm.assets, ({ categoryId }) =>
        categoryWeight(categoryId)
      )
      entryForm.liabilities = sortBy(entryForm.liabilities, ({ categoryId }) =>
        categoryWeight(categoryId)
      )
      return entryForm
    }
  },
  editEntryForm: (state, getters, rootState, rootGetters) => {
    const categoryWeight = rootGetters['categories/categoryWeight']
    return entry => {
      const entryForm = cloneDeep(
        pick(entry, ['date', 'assets', 'liabilities', 'note'])
      )
      entryForm.assets = sortBy(entryForm.assets, ({ categoryId }) =>
        categoryWeight(categoryId)
      )
      entryForm.liabilities = sortBy(entryForm.liabilities, ({ categoryId }) =>
        categoryWeight(categoryId)
      )
      return entryForm
    }
  },
  lastEntry: ({ entries }) => (date = dayjs()) => {
    const windowStart = dayjs(date)
    for (let idx = 0; idx < entries.length; ++idx) {
      if (windowStart.isSameOrAfter(entries[idx].date)) {
        return entries[idx]
      }
    }
    return null
  },
  nextEntry: ({ entries }) => date => {
    const windowEnd = dayjs(date)
    for (let idx = entries.length - 1; idx >= 0; --idx) {
      if (windowEnd.isSameOrBefore(entries[idx].date)) {
        return entries[idx]
      }
    }
    return null
  },
  entryDates: state => state.entries.map(({ date }) => date).reverse()
}
