import dayjs from '@/dayjs'

import firestore from '@/firebase/async-firestore'

let clearGoalListener

export default {
  async loadGoals({ state, rootState, commit }) {
    const db = await firestore()
    commit('setLoadingGoals', true)
    if (clearGoalListener !== undefined) {
      clearGoalListener()
      clearGoalListener = undefined
    }
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('goals')
    clearGoalListener = collection.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added' || change.type === 'modified') {
          commit('loadGoal', { ...change.doc.data(), id: change.doc.id })
        }
        if (change.type === 'removed') {
          commit('removeGoal', change.doc.id)
        }
      })
      if (state.loadingGoals) {
        commit('setLoadingGoals', false)
      }
    })
  },
  cleanup({ commit }) {
    if (clearGoalListener) clearGoalListener()
    commit('clearGoals')
  },
  async saveGoal({ dispatch }, form) {
    const { model, ref } = await dispatch('prepareInsert', form)
    await ref.set(model)
  },
  async deleteGoal({ rootState }, goalId) {
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('goals')
    const ref = collection.doc(goalId)
    await ref.delete()
  },
  async prepareInsert({ rootState, state }, form) {
    const goalDate = dayjs(form.goalDate)
    if (!goalDate.isValid()) {
      throw new Error('invalid-date')
    }
    const model = {
      ...form,
      date: goalDate.format('YYYY-MM-DD')
    }
    delete model.goalDate
    const db = await firestore()
    // Check for uniqueness on model.name
    Object.values(state.goals).forEach(({ id, date }) => {
      if (id === model.id) {
        return
      }
      if (date === model.date) {
        throw new Error('not-unique')
      }
    })
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('goals')
    const ref = 'id' in model ? collection.doc(model.id) : collection.doc()
    return { model, ref }
  }
}
