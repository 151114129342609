export default {
  appTitle: process.env.VUE_APP_TITLE,
  networkOnLine: true,
  SWRegistrationForNewContent: null,
  showAddToHomeScreenModalForApple: false,
  refreshingApp: false,
  isTWA: sessionStorage.getItem('isTWA') || false,
  activeNav: null,
  enableAnnounceKit: process.env.VUE_APP_ANNOUNCEKIT_ENABLE === '1',
  isPrerendering:
    window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendering
}
