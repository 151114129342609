import { isNil } from 'lodash'

export default {
  isUserLoggedIn: state => !isNil(state.user),
  userNeedsEmailVerification: state => state.user && !state.user.emailVerified,
  isFreePlan: state => state.user && state.user.plan === 'starter',
  user: state => state.user,
  categoryOrder: state => state.user && state.user.preferences.categoryOrder,
  showExchangeRate: state =>
    state.user && state.user.preferences.showExchangeRate
}
