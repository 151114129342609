<template>
  <div></div>
</template>
<script>
export default {
  name: 'BlankView',
  metaInfo: {
    title: 'Starting...'
  }
}
</script>
