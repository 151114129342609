import { findIndex, orderBy } from 'lodash'
import { migrateEntry } from './entries.migrations.js'

export default {
  addEntry(state, entry) {
    state.entries = orderBy(
      state.entries.concat(migrateEntry(entry)),
      ['id'],
      ['desc']
    )
  },
  setLoadingEntries(state, value) {
    state.loadingEntries = value
  },
  updateEntry(state, entry) {
    const index = findIndex(state.entries, ({ id }) => id === entry.id)
    if (~index) {
      state.entries[index] = migrateEntry(entry)
      state.entries = orderBy(state.entries, ['id'], ['desc'])
    }
  },
  removeEntry(state, entryId) {
    const index = findIndex(state.entries, ({ id }) => id === entryId)
    if (~index) {
      state.entries.splice(index, 1)
    }
  },
  clearEntries(state) {
    state.entries = []
  }
}
