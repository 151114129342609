import Vue from 'vue'

export default {
  enable(state, { id }) {
    Vue.set(state.enabled, id, true)
  },
  disable(state, { id }) {
    Vue.set(state.enabled, id, false)
  },
  toggle(state, { id }) {
    Vue.set(state.enabled, id, !state.enabled[id])
  },
  load(state, enabled) {
    state.enabled = enabled
  }
}
