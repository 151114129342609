import firebase from 'firebase/app'
import { isNil } from 'lodash'

let asyncFirestore = null

// A method to clear all firebase & app caches on boot
// Doesn't delete localforage because that's less likely to go awry
async function maybeResetApp() {
  if (typeof window !== 'undefined' && window.resetApp && window.indexedDB) {
    // Clear indexedDB
    if ('databases' in window.indexedDB) {
      const dbs = await window.indexedDB.databases()
      await Promise.all(
        dbs.map(db => {
          if (db.name.match(/firebase|firestore/)) {
            return window.indexedDB.deleteDatabase(db.name)
          }
          return false
        })
      )
    } else {
      // Fallback - delete by string name (less reliable)
      const appName = firebase.app().name
      const { projectId } = firebase.app().options
      window.indexedDB.deleteDatabase(`firestore/${appName}/${projectId}/main`)
      window.indexedDB.deleteDatabase(`firebaseLocalStorageDb`)
      window.indexedDB.deleteDatabase(`firebase-installations-database`)
    }
    // Clear storages
    sessionStorage.clear()
    localStorage.clear()
    // Unregister all service workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      await Promise.all(
        registrations.map(registration => registration.unregister())
      )
    }
    // Clear all service worker caches
    if ('caches' in window) {
      const keys = await caches.keys()
      await Promise.all(keys.map(key => caches.delete(key)))
    }
    // Tell the app to reload
    throw new Error('reload')
  }
}

// Lazy load firestore with async import is important for performance

export default () => {
  if (isNil(asyncFirestore)) {
    asyncFirestore = import(
      /* webpackChunkName: "chunk-firestore" */ 'firebase/firestore'
    )
      .then(maybeResetApp)
      .then(() => {
        firebase.firestore().settings({})
        firebase.firestore().enablePersistence({ synchronizeTabs: true })
        // firebase.firestore.setLogLevel('debug')
        return firebase.firestore()
      })
      .catch(error => {
        if (error.message === 'reload') {
          document.location = '/login'
          return null
        }
        console.error(error)
        throw error
      })
  }
  return asyncFirestore
}
