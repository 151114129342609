import state from './categories.state'
import mutations from './categories.mutations'
import actions from './categories.actions'
import getters from './categories.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
