export default function migrate(category, state) {
  // Migrate flatChanges
  if ('flatChanges' in category) {
    category.flatChanges.forEach(change => {
      // Add indexation if missing
      if (!('indexation' in change)) {
        change.indexation = 'none'
        change.indexFactor = '0'
        change.indexPeriod = 'year'
      }
      // Add subunit if missing
      if (!('subunit' in change)) {
        change.subunit = null
      }
    })
  }
  // Migrate custom sort order
  if (!('weight' in category)) {
    const defaultWeight = Object.keys(state.categories).length
    category.weight = defaultWeight
  }
  return category
}
