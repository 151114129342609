// import firebase from 'firebase/app'
// import firestore from '@/firebase/async-firestore'
import router from '@/router'

export default {
  /**
   * Fired when a user loads the page or registers
   * Checks whether they've chosen a paid plan and redirects
   * them to stripe if they haven't already set it up
   *
   * Schema:
   *
   * paymentState<enum>
   */
  checkPaymentStatus: async (store, userRecord) => {
    // userRecord is falsey when they've just deleted their account
    if (!userRecord) return

    // Free plans don't need payment status monitoring
    if (userRecord.plan === 'starter') return

    // User needs to go through checkout
    if (userRecord.paymentState === 'needed') {
      const currentRouter = router.app.$route
      if (currentRouter.meta && currentRouter.meta.isInterstitial) return
      router.push('/billing/init').catch(() => {})
    }
  }
}
