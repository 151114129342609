import store from '@/store'
import { formatCurrency } from '@/misc/filters'

export default function currencyFormatter(
  amount,
  currencyWithSubunit,
  overrides = {}
) {
  const defaultOptions = store.getters['currencies/currencyFormatOptions'](
    currencyWithSubunit
  )
  const { currency, minPrecision, maxPrecision, format, maxSF } = {
    ...defaultOptions,
    ...overrides
  }
  return formatCurrency(amount, currency, {
    minPrecision,
    maxPrecision,
    format,
    maxSF
  })
}
