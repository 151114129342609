export function migrateHolding(holding) {
  // Add blank subunit prop if missing - required for v-bind.sync
  if (!('subunit' in holding)) {
    holding.subunit = null
  }
  return holding
}

export function migrateEntry(entry) {
  entry.assets.forEach(migrateHolding)
  entry.liabilities.forEach(migrateHolding)
  return entry
}
