import state from './scenarios.state'
import mutations from './scenarios.mutations'
import actions from './scenarios.actions'
import getters from './scenarios.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
