import state from './plan.state'
import mutations from './plan.mutations'
import actions from './plan.actions'
import getters from './plan.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
