const adminRoutes = [
  {
    path: '/admin',
    name: 'admin_home',
    component: () =>
      import(/* webpackChunkName: "chunk-admin" */ '@/views/admin/Home.vue')
  }
]

adminRoutes.forEach(route => {
  route.meta = { isAdmin: true, activeNav: 'admin' }
})

export default adminRoutes
