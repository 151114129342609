import state from './currencies.state'
import mutations from './currencies.mutations'
import actions from './currencies.actions'
import getters from './currencies.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
