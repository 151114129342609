<template lang="pug">
.loading-page
  h1.title Loading...
  b-progress(type='is-primary')
</template>

<script>
import { mapState } from 'vuex'
import { isNil } from 'lodash'

export default {
  computed: mapState('authentication', ['user']),
  watch: {
    user: {
      handler(user) {
        if (user === undefined) return

        if (this.$route.query.redirectUrl === this.$route.path) {
          this.$router.replace('/').catch(() => {})
          return
        }

        const redirectUrl = isNil(user)
          ? { path: '/login', params: this.$route.query }
          : this.$route.query.redirectUrl

        this.$router.replace(redirectUrl).catch(() => {})
      },
      immediate: true
    }
  },
  metaInfo: {
    title: 'Loading...'
  }
}
</script>

<style lang="sass" scoped>
.loading-page
  position:        fixed
  top:             0
  left:            0
  right:           0
  bottom:          0
  z-index:         250
  background:      white
  display:         flex
  flex-direction: column
  align-items:     center
  justify-content: center
.progress-wrapper
  width: 250px
</style>
