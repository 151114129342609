import dayjs from '@/dayjs'

const dateFormats = {
  day: 'Do MMMM YYYY',
  short: 'Do MMM YYYY',
  month: 'MMMM YYYY',
  noyear: 'MMMM Do',
  year: 'YYYY'
}

export function formatDate(date, format = 'day') {
  if (!(format in dateFormats)) format = 'day'
  return dayjs(date).format(dateFormats[format])
}

export function formatNumber(
  amount,
  { minPrecision, maxPrecision, maxSF, format = {} }
) {
  const rounded = new Intl.NumberFormat('en-US', {
    useGrouping: false,
    maximumSignificantDigits: maxSF
  }).format(amount)
  const precision = {}
  if (minPrecision !== undefined) {
    precision.minimumFractionDigits = minPrecision
  }
  if (maxPrecision !== undefined) {
    precision.maximumFractionDigits = maxPrecision
  }
  return new Intl.NumberFormat(navigator.language, {
    ...format,
    ...precision
  }).format(Number(rounded))
}

export function formatCurrency(
  amount,
  currency,
  { maxPrecision = 20, minPrecision = 0, format = null, maxSF = 21 } = {}
) {
  // Add zero to remove negative zero
  amount += 0
  const currencyId = currency.split('.', 2)[0]
  const formattedAmount = formatNumber(amount, {
    minPrecision,
    maxPrecision,
    maxSF
  })
  // Apply custom format if present
  if (format !== null) {
    return format.replace('{amount}', formattedAmount)
  }
  // More general format rules
  // Crypto gets the ticker + the decimal amount
  if (currencyId[0] === '฿') {
    return `${currencyId.slice(1)} ${formattedAmount}`
  }
  // If the currency ID starts with a symbol, it's used as the currency symbol
  if (!currencyId[0].match(/[A-Z]/)) {
    const symbol = [...currencyId][0]
    return `${symbol} ${formattedAmount}`
  }
  // Otherwise, use the built in browser currency formatting
  // Allow the removal of decimals (useDecimals results in maxPrecision 0)
  if (maxPrecision === 0) {
    return formatNumber(amount, {
      minPrecision,
      maxPrecision,
      maxSF,
      format: { style: 'currency', currency: currencyId }
    })
  }
  // Rely on browser default precision for actual currencies
  // which have decimals turned on
  return formatNumber(amount, {
    maxSF,
    format: { style: 'currency', currency: currencyId }
  })
}

export function timeUntil(date, currentDate = dayjs()) {
  return dayjs(date).from(currentDate, true)
}
