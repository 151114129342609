<template lang="pug">
p Resetting nutworth...
</template>

<script>
import firestore from '@/firebase/async-firestore'

export default {
  mounted() {
    window.resetApp = true
    firestore()
  }
}
</script>
