import migrate from './scenarios.migrations.js'

export default {
  getScenarios(state, getters, rootState) {
    return (rootState.authentication.user.scenarios || []).map(record => {
      const scenario = {
        ...record,
        enabled: state.enabled[record.id]
      }
      return migrate(scenario)
    })
  },
  activeScenarios(state, getters) {
    return getters.getScenarios.filter(({ enabled }) => enabled)
  },
  scenariosById(state, getters) {
    const index = {}
    getters.getScenarios.forEach(scenario => {
      index[scenario.id] = scenario
    })
    return index
  },
  getScenarioById: (state, getters) => id => getters.scenariosById[id],
  getChangeCurrency(state, getters, rootState, rootGetters) {
    const getCategoryLastCurrency =
      rootGetters['categories/getCategoryLastCurrency']
    return ({ holdingId }) => getCategoryLastCurrency({ id: holdingId })
  },
  scenarioNeedsUpdate(state, getters) {
    const updateState = {}
    getters.getScenarios.forEach(scenario => {
      updateState[scenario.id] = false
      scenario.actions.forEach(action => {
        action.changes.forEach(change => {
          if (change.operation !== 'flat') {
            return
          }
          if (!change.configCurrency) {
            updateState[scenario.id] = true
            return
          }
          const { currency, subunit } = getters.getChangeCurrency(change)
          if (
            change.configCurrency.currency !== currency ||
            change.configCurrency.subunit !== subunit
          ) {
            updateState[scenario.id] = true
          }
        })
      })
    })
    return ({ id }) => updateState[id]
  }
}
