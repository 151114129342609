import state from './goals.state'
import mutations from './goals.mutations'
import actions from './goals.actions'
import getters from './goals.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
