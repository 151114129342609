import Vue from 'vue'
import dayjs from '@/dayjs'
import migrate from './goals.migrations.js'

export default {
  setLoadingGoals(state, value) {
    state.loadingGoals = value
  },
  loadGoal(state, goal) {
    goal.goalDate = dayjs(goal.date).toDate()
    Vue.set(state.goals, goal.id, migrate(goal))
  },
  removeGoal(state, goalId) {
    Vue.delete(state.goals, goalId)
  },
  clearGoals(state) {
    Object.keys(state.goals).forEach(goalId => {
      Vue.delete(state.goals, goalId)
    })
  }
}
