import { isNil } from 'lodash'

let asyncZipcelx = null

export default function() {
  if (isNil(asyncZipcelx)) {
    asyncZipcelx = import(
      /* webpackChunkName: "chunk-zipcelx" */ 'zipcelx'
    ).then(module => module.default)
  }
  return asyncZipcelx
}
