import localforage from 'localforage'
import { cloneDeep } from 'lodash'
import { saveUserProfile } from '@/misc/helpers'

function prepareAction(action) {
  return cloneDeep(action)
}

export default {
  async initialize({ commit }, userId) {
    const enabled = await localforage.getItem(`${userId}/scenarios/enabledmap`)
    if (enabled) {
      commit('load', enabled)
    }
  },
  async cleanup({ commit }) {
    commit('load', {})
  },
  async syncStorage({ state, getters, rootState }) {
    const enabled = {}
    const userId = rootState.authentication.user.id
    // GC
    Object.keys(getters.scenariosById).forEach(scenarioId => {
      if (state.enabled[scenarioId]) enabled[scenarioId] = true
    })
    await localforage.setItem(`${userId}/scenarios/enabledmap`, enabled)
  },
  async prepareScenario(store, { id, name, actions = [] }) {
    const scenario = { id, name, actions: actions.map(prepareAction) }
    if (!scenario.id) {
      scenario.id = (+Date.now()).toString()
    }
    if (!scenario.name) {
      throw new Error('Your scenario needs a name!')
    }
    return scenario
  },
  async toggleEnabled(store, scenario) {
    store.commit('toggle', scenario)
    return store.dispatch('syncStorage')
  },
  async createScenario(store, scenario) {
    const prepared = await store.dispatch('prepareScenario', scenario)
    const scenarios = (
      store.rootState.authentication.user.scenarios || []
    ).slice()
    scenarios.push(prepared)
    const doc = {
      ...store.rootState.authentication.user,
      scenarios
    }
    return saveUserProfile(doc).then(() => {
      store.commit('enable', prepared)
      return store.dispatch('syncStorage')
    })
  },
  async updateScenario(store, scenario) {
    const prepared = await store.dispatch('prepareScenario', scenario)
    const scenarios = (
      store.rootState.authentication.user.scenarios || []
    ).slice()
    let found = false
    scenarios.forEach(({ id }, idx) => {
      if (found) {
        return
      }
      if (id === scenario.id) {
        scenarios[idx] = prepared
        found = true
      }
    })
    if (!found) {
      throw new Error(`Can't find scenario with ID ${scenario.id}`)
    }
    const doc = {
      ...store.rootState.authentication.user,
      scenarios
    }
    return saveUserProfile(doc)
  },
  async deleteScenario(store, scenarioId) {
    let { scenarios } = store.rootState.authentication.user
    scenarios = scenarios.filter(({ id }) => id !== scenarioId)
    const doc = {
      ...store.rootState.authentication.user,
      scenarios
    }
    return saveUserProfile(doc)
  },
  async saveSortOrder(store, scenarios) {
    const prepared = await Promise.all(
      scenarios.map(async scenario =>
        store.dispatch('prepareScenario', scenario)
      )
    )
    const doc = {
      ...store.rootState.authentication.user,
      scenarios: prepared
    }
    return saveUserProfile(doc)
  },
  async lazyMigrate({ getters, dispatch }) {
    const promises = []
    getters.getScenarios.forEach(scenario => {
      const localScenario = cloneDeep(scenario)
      let needsSave = false

      localScenario.actions.forEach(action => {
        action.changes.forEach(change => {
          if (!('configCurrency' in change)) {
            change.configCurrency = getters.getChangeCurrency(change)
            needsSave = true
          }
        })
      })

      if (needsSave) {
        promises.push(dispatch('updateScenario', localScenario))
      }
    })
    await Promise.all(promises)
  }
}
