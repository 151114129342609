<template lang="pug">
.app-navbar
  .app-navbar-inner
    router-link.brand(:to="isUserLoggedIn ? '/portfolio/data' : '/'")
      img.logo(alt="nutworth logo" src="@/assets/img/nutworth-logo.png")
    .app-activities(:class="{'has-4-links': showAdmin}")
      .is-loading(v-if="isPendingAuth") Loading...
      router-link(to="/portfolio/data" v-if="isUserLoggedIn" :class='{active: entriesActive}') My Data
      router-link(to="/portfolio/analyze" v-if="isUserLoggedIn" :class='{active: analyzeActive}') Past
      router-link(to="/portfolio/projections" v-if="isUserLoggedIn" :class='{active: projectionsActive}') Future
      router-link(to="/portfolio/share" v-if="isUserLoggedIn" :class='{active: shareActive}') Share
      router-link(to="/admin" v-if="showAdmin" :class='{active: adminActive}') Admin
      .active-underline
    .app-context
      .is-offline(tag="div" v-if="!networkOnLine") Offline
      a#announce-kit(v-show="isUserLoggedIn && networkOnLine" title="New Stuff!"  v-if="!isPrerendering && enableAnnounceKit")
        announce-kit(catch-click="#announce-kit" widget="https://announcekit.app/widgets/v2/3V16Mw" :user='announcekitUser' style="display: flex; align-items: center; padding: 0.25rem")
      b-icon.is-primary.is-pro(v-if="isUserLoggedIn && networkOnLine && user.plan !== 'starter' && user.paymentState == 'paid'" icon="crown" title="Pro plan - thank you for your support!")
      router-link.button.upgrade-nag.is-primary.is-outlined(v-if="isUserLoggedIn && networkOnLine && user.plan === 'starter'" :to='{name: "billing_plan", query: {action: "upgrade"}}')
        span.visible-sm Upgrade
        span.hidden-sm Upgrade to Pro
      b-dropdown(v-if="isUserLoggedIn && networkOnLine" position='is-bottom-left')
        b-button.menu-trigger(slot="trigger")
          b-icon(icon='bars')
        b-dropdown-item.hello(:focusable="false" custom)
          .hello-title Signed in as:
          .user-email| {{ user.email }}
        b-dropdown-item.settings-link(has-link aria-role="listitem")
          router-link(:to="{name: 'general_settings'}") Edit Settings
        b-dropdown-item(aria-role="listitem" has-link)
          router-link(:to="{name: 'import'}") Import from Spreadsheet
        b-dropdown-item(aria-role="listitem" has-link)
          router-link(:to="{name: 'export'}") Export to Spreadsheet
        b-dropdown-item.settings-link(has-link aria-role="listitem" v-if="enableAnnounceKit")
          a(href="https://announcekit.app/nutworth/product-news" target="_blank" @click="launchAnnounceKit") Product News
        b-dropdown-item.settings-link(has-link aria-role="listitem")
          a(href="mailto:support@nutworth.com" target="_blank") Get Help
        b-dropdown-item(aria-role="listitem" @click.native="signOut") Sign out
      router-link.button.is-primary(:to="{name: 'sign_up'}" v-if="isNoUserLoggedIn && networkOnLine")
        strong Try it Free
      router-link.button.is-light(to="/login" v-if="isNoUserLoggedIn && networkOnLine") Sign in
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ToastProgrammatic as Toast } from 'buefy'
import { get } from 'lodash'
import AnnounceKit from 'announcekit-vue'

export default {
  components: {
    AnnounceKit
  },
  computed: {
    ...mapState('app', [
      'networkOnLine',
      'appTitle',
      'enableAnnounceKit',
      'isPrerendering'
    ]),
    ...mapState('authentication', ['user']),
    ...mapGetters('app', [
      'entriesActive',
      'analyzeActive',
      'projectionsActive',
      'shareActive',
      'adminActive'
    ]),
    isUserLoggedIn() {
      return !!this.user
    },
    isPendingAuth() {
      return this.user === undefined
    },
    isNoUserLoggedIn() {
      return this.user === null
    },
    showAdmin() {
      return this.isUserLoggedIn && this.user.isAdmin
    },
    announcekitUser() {
      if (this.user) {
        return { id: this.user.id }
      }
      return {}
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        if (user) {
          document.body.classList.add('user-logged-in')
          document.body.classList.remove('user-logged-out')
        } else {
          document.body.classList.add('user-logged-out')
          document.body.classList.remove('user-logged-in')
        }
      }
    }
  },
  mounted() {
    document.body.classList.add('has-navbar-fixed-top')
  },
  destroyed() {
    document.body.classList.remove('has-navbar-fixed-top')
  },
  methods: {
    ...mapActions('authentication', ['logout']),
    signOut() {
      this.logout().then(() => {
        Toast.open('Signed out successfully.')
      })
    },
    launchAnnounceKit($event) {
      const widget = get(window, ['announcekit', 'widgets', 0], null)
      if (widget && !($event.ctrlKey || $event.metaKey)) {
        $event.preventDefault()
        widget.open()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@mixin has-no-shadow
  box-shadow: none
@mixin has-shadow
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
@mixin has-big-shadow
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
::v-deep .dropdown-content
  @include has-big-shadow
.app-navbar
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 3.25rem
  @include has-shadow
  z-index: 40
  background: white
.app-navbar-inner
  height: 3.25rem
  width: 100%
  max-width: 1300px
  margin: 0 auto
  display: flex
  align-items: center
.brand
  display: flex
  align-items: center
  font-size: 1.5rem
  font-family: Roboto, sans-serif
  color: #333
  padding: 0 0.5rem
  height: 3.25rem
  img
    max-width: 70%
    padding-left: 25px
    @media screen and (max-width: 768px)
      padding-left: 10px
  &:first-child
    padding: 0
  .site-name
    line-height: 100%
    margin-left: 0.25rem
.app-activities a, .brand, .is-loading
  color: #333
  display: block
  padding: 0 0.5rem
  height: 3.25rem
  display: flex
  align-items: center
  &:active, &:hover
    background: hsl(119, 33%, 97%)
  &:visited
    color: #333
.app-activities
  height: 3.25rem
  display: flex
  align-items: flex-start
  position: relative
  a
    flex: 1 1 auto
    width: 100%
    min-width: 80px
    max-width: 100px
    display: flex
    justify-content: center
    color: #333
    transition: color 0.25s
    &:hover, &:active
      color: hsl(110, 30%, 25%) !important
    &:visited
      color: #333
    &.active, &.active:visited
      color: #333
.active-underline
  position: absolute
  left: 0
  bottom: 0
  width: 0
  background: #43ae43
  height: 3px
  transition: left 0.2s ease-out, width 0.2s ease-out
.active
  &:nth-child(1) ~ .active-underline
    width: 25%
  &:nth-child(2) ~ .active-underline
    left: 25%
    width: 25%
  &:nth-child(3) ~ .active-underline
    left: 50%
    width: 25%
  &:nth-child(4) ~ .active-underline
    left: 75%
    width: 25%
.has-4-links
  .active
    &:nth-child(1) ~ .active-underline
      width: 20%
    &:nth-child(2) ~ .active-underline
      left: 20%
      width: 20%
    &:nth-child(3) ~ .active-underline
      left: 40%
      width: 20%
    &:nth-child(4) ~ .active-underline
      left: 80%
      width: 20%
    &:nth-child(5) ~ .active-underline
      left: 80%
      width: 20%
.app-context
  margin-left: auto
  margin-right: 0.5rem
  display: flex
  flex-direction: row
  > *
    margin-left: 0.5rem
.is-offline
  font-weight: bold
  color: #ffd500 !important
  text-transform: uppercase
.visible-sm
  display: none
@media screen and (max-width: 1023px)
  .has-link a, .dropdown-item
    padding: 1rem 1.5rem !important
@media screen and (max-width: 768px)
  .visible-sm
    display: inline-block
  .hidden-sm
    display: none
  .app-navbar
    @include has-no-shadow
  .app-activities
    position: fixed
    top: 3.25rem
    height: 2.75rem
    background: white
    width: 100%
    @include has-shadow
    a
      height: 2.75rem
    > *
      flex: 1 1 auto
      justify-content: center
      max-width: none !important
.menu-trigger, .menu-trigger ::v-deep > span
  display: flex
  align-items: center
  justify-content: center
.hello
  border-bottom: 1px solid #ddd
  padding-bottom: 0.75rem
  margin-bottom: 0.5rem
.hello-title
  font-weight: bold
.user-email
  whitespace: no-wrap
  max-width: 280px
  text-overflow: ellipsis
  overflow: hidden
.changelog-wrapper
  position: relative
.is-pro
  color: green
  font-size: 1rem
  height: 2.5rem
  &:hover
    animation: rainbow 1s infinite
#announce-kit
  display: flex
  align-items: center
</style>
<style unscoped lang="sass">
@media screen and (max-width: 768px)
  body.has-navbar-fixed-top
    min-width: 360px
  body.user-logged-in.has-navbar-fixed-top
    padding-top: 6rem
  body.user-logged-out.has-navbar-fixed-top .app-activities
    display: none
@keyframes rainbow
  0%
    color: #43ae43
  50%
    color: #FFD300
  100%
    color: #43ae43
</style>
