/* eslint-disable */
const trackerEnabled = true
let analyticsAttributes = {}

export function pageImpression(extraAttributes) {
  if (!trackerEnabled) return
}

export function setAnalyticsAttributes(newAttributes) {
  if (!trackerEnabled) return
  analyticsAttributes = { ...newAttributes }
}

export function actionTaken(key, value) {
  if (!trackerEnabled) return 
  dataLayer.push({event: '' + key + ''});
}
