import state from './calculator.state'
import mutations from './calculator.mutations'
import actions from './calculator.actions'
import getters from './calculator.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
