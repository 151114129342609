import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import { isNil, get } from 'lodash'
import CheckLogin from '@/views/CheckLogin'
import store from '@/store'
import adminRoutes from '@/router/admin'
import Blank from '@/views/Blank'
import ResetApp from '@/views/ResetApp'

Vue.use(Router)
Vue.use(VueMeta)

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from) {
    if (to.name === from.name) return false
    return { x: 0, y: 0 }
  },
  routes: [
    ...adminRoutes,
    {
      path: '/',
      name: 'entries',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-about" */ '@/views/Entries.vue'
        ),
      meta: {
        authNotRequired: false
      }
    },
    {
      path: '/app_boot',
      name: 'app_boot',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-appboot" */ '@/views/AppBoot.vue'
        ),
      meta: {
        authNotRequired: true,
        isInterstitial: true
      }
    },
    {
      path: '/embed/area',
      name: 'embed_area',
      component: () =>
        import(
          /* webpackChunkName: "chunk-area-embed" */ '@/views/embed/Area.vue'
        ),
      meta: {
        authNotRequired: true,
        isInterstitial: true,
        hasOwnLayout: true
      }
    },
    {
      path: '/embed/bar',
      name: 'embed_bar',
      component: () =>
        import(
          /* webpackChunkName: "chunk-bar-embed" */ '@/views/embed/Bar.vue'
        ),
      meta: {
        authNotRequired: true,
        isInterstitial: true,
        hasOwnLayout: true
      }
    },
    {
      path: '/embed/pie',
      name: 'embed_pie',
      component: () =>
        import(
          /* webpackChunkName: "chunk-pie-embed" */ '@/views/embed/Pie.vue'
        ),
      meta: {
        authNotRequired: true,
        isInterstitial: true,
        hasOwnLayout: true
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-about" */ '@/views/About.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about/avocados',
      name: 'avocados',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-avocados" */ '@/views/about/Avocados.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about/sharesight-integration',
      name: 'about_sharesight',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-about-sharesight" */ '@/views/about/SharesightIntegration.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about/plans',
      name: 'plans',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-plans" */ '@/views/Plans.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about/privacy',
      name: 'privacy_policy',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-legal" */ '@/views/PrivacyPolicy.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/about/terms',
      name: 'terms',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-legal" */ '@/views/Terms.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/simple-net-worth-calculator',
      name: 'calculator',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-calculator" */ '@/views/Calculator.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/sign_up',
      name: 'sign_up',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-authentication" */ '@/views/SignUp.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-authentication" */ '@/views/Login.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/email_preferences',
      name: 'email_preferences',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-mail-preferences" */ '@/views/EmailPreferences.vue'
        ),
      meta: {
        authNotRequired: true,
        isInterstitial: true
      }
    },
    {
      path: '/auth/email/action',
      name: 'auth_email_action',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-authentication" */ '@/views/AuthEmailHandler.vue'
        ),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-authentication" */ '@/views/Welcome.vue'
        )
    },
    {
      path: '/settings',
      name: 'general_settings',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-user-settings" */ '@/views/Settings.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/settings/account',
      name: 'account_settings',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-user-settings" */ '@/views/AccountSettings.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/settings/email_invalid',
      name: 'email_invalid',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-user-email-invalid" */ '@/views/EmailInvalid.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/settings/categories',
      name: 'category_settings',
      component: () =>
        import(
          /* webpackChunkName: "chunk-category-settings" */ '@/views/CategorySettings.vue'
        )
    },
    {
      path: '/billing/init',
      name: 'billing_init',
      component: () =>
        import(
          /* webpackChunkName: "chunk-billing" */ '@/views/billing/Init.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/billing/update',
      name: 'billing_update',
      component: () =>
        import(
          /* webpackChunkName: "chunk-billing" */ '@/views/billing/Update.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/billing/choose_plan',
      name: 'billing_plan',
      component: () =>
        import(
          /* webpackChunkName: "chunk-billing" */ '@/views/billing/Plan.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/billing/pending',
      name: 'billing_pending',
      component: () =>
        import(
          /* webpackChunkName: "chunk-billing" */ '@/views/billing/Pending.vue'
        ),
      meta: {
        isInterstitial: true
      }
    },
    {
      path: '/portfolio/data',
      name: 'entries',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-entries" */ '@/views/Entries.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/export',
      name: 'export',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-export" */ '@/views/Export.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/import',
      name: 'import',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-import" */ '@/views/Import.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/share',
      name: 'share',
      component: () =>
        import(/* webpackChunkName: "chunk-share" */ '@/views/Share.vue'),
      meta: {
        activeNav: 'share'
      }
    },
    {
      path: '/portfolio/data/new',
      name: 'new_entry',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-entry-editor" */ '@/views/NewEntry.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/data/:entryId',
      name: 'edit_entry',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-entry-editor" */ '@/views/EditEntry.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/goals',
      name: 'goals',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-goals" */ '@/views/Goals.vue'
        ),
      meta: {
        activeNav: 'entries'
      }
    },
    {
      path: '/portfolio/analyze',
      name: 'analyze',
      component: () =>
        import(/* webpackChunkName: "chunk-analyze" */ '@/views/Analyze.vue'),
      meta: {
        activeNav: 'analyze'
      }
    },
    {
      path: '/portfolio/projections',
      name: 'projections',
      component: () =>
        import(
          /* webpackChunkName: "chunk-projections" */ '@/views/Projections.vue'
        ),
      meta: {
        activeNav: 'projections'
      }
    },
    // Integration manager
    {
      path: '/integrations',
      name: 'integrations',
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-integrations" */ '@/views/Integrations.vue'
        )
    },
    // Sharesight integration
    {
      path: '/integrations/sharesight/auth',
      name: 'sharesight_auth',
      meta: {
        hasOwnLayout: true,
        authNotRequired: true
      },
      component: () =>
        import(
          /* webpackChunkName: "client-chunk-sharesight" */ '@/views/sharesight/Auth.vue'
        )
    },
    // Reset screen
    {
      path: '/resetapp',
      name: 'resetapp',
      meta: {
        hasOwnLayout: true,
        authNotRequired: true
      },
      component: ResetApp
    },
    // This are utility routes used by the service worker to prevent a flash of
    // content from the wrong screen
    {
      path: '/boot',
      meta: { hasOwnLayout: true, authNotRequired: true },
      component: Blank
    },
    {
      path: '/boot/index.html',
      meta: { hasOwnLayout: true, authNotRequired: true },
      component: Blank
    },
    { path: '*', redirect: '/' }
  ]
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  // authNotRequired screens are always accessible
  if (to.meta && to.meta.authNotRequired) {
    return next()
  }
  // The rest of the app needs a user, so we redirect to the login page
  if (isNil(store.state.authentication.user)) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    const route = {
      path,
      query: {
        redirectUrl: to.fullPath
      }
    }
    return next(route)
  }
  const {
    isAdmin,
    paymentState,
    emailInvalid
  } = store.state.authentication.user
  // Admin routes need the admin flag, guard the route
  if (to.meta && to.meta.isAdmin && !isAdmin) {
    return next('/')
  }
  // Interstitial pages are always accessible without redirects if they're
  // logged in
  if (to.meta && to.meta.isInterstitial) {
    return next()
  }
  // If a user has an invalid email, we force them to update it.
  if (emailInvalid) {
    return next('/settings/email_invalid')
  }
  // If we have a user & their payment hasn't gone through, they need to
  // either make payment or update their billing details. So redirect them
  // through that workflow
  if (~['needed', 'failed'].indexOf(paymentState)) {
    if (paymentState === 'failed') {
      return next('/billing/update')
    }
    return next('/billing/init')
  }

  return next()
})

// Connect route metadata to vuex for the nav bar
router.afterEach(to => {
  const activeNav = get(to, 'meta.activeNav', null)
  store.commit('app/setActiveNav', activeNav)
})

export default router
