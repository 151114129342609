<template lang="pug">
span(:class='loading && "loading"') {{ formattedValue }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import currencyFormatter from '@/misc/currencyFormatter'

export default {
  props: {
    value: Number,
    currency: String,
    date: Date,
    options: Object
  },
  data: () => ({ loading: true, rate: null }),
  computed: {
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    formattedValue() {
      if (this.loading) return 'Loading...'
      if (this.rate === null) return 'Unknown'
      return currencyFormatter(
        this.value * this.rate,
        this.displayCurrency,
        this.options || {}
      )
    },
    displayCurrency() {
      if (this.currency) return this.currency
      if (this.activeCurrencyWithSubunit) return this.activeCurrencyWithSubunit
      return null
    }
  },
  watch: {
    date: {
      immediate: true,
      handler() {
        this.reloadRate()
      }
    },
    displayCurrency: {
      handler() {
        this.reloadRate()
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    reloadRate() {
      this.rate = null
      this.loading = true
      const args = {
        currency: this.displayCurrency,
        date: this.date
      }
      if (!args.currency) return
      this.getExchangeRateOn(args)
        .then(rate => {
          this.rate = rate
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="sass"></style>
