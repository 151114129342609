import firebase from 'firebase/app'
import dayjs from '@/dayjs'

import firestore from '@/firebase/async-firestore'
import zipcelx from '@/misc/async-zipcelx'
import { prepareEntry } from '@/misc/helpers'

let clearEntryListener

function loadEntry(doc) {
  return {
    id: doc.id,
    date: dayjs(doc.id).toDate(),
    note: '',
    ...doc.data()
  }
}

export default {
  async loadEntries({ state, rootState, commit }) {
    const db = await firestore()
    commit('setLoadingEntries', true)
    if (clearEntryListener !== undefined) {
      clearEntryListener()
      clearEntryListener = undefined
    }
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('entries')
    clearEntryListener = collection.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          commit('addEntry', loadEntry(change.doc))
        }
        if (change.type === 'modified') {
          commit('updateEntry', loadEntry(change.doc))
        }
        if (change.type === 'removed') {
          commit('removeEntry', change.doc.id)
        }
      })
      if (state.loadingEntries) {
        commit('setLoadingEntries', false)
      }
    })
  },
  async prepareInsert(store, entryForm) {
    return prepareEntry(entryForm)
  },
  async validate(store, { entry, collection, isNew }) {
    if (isNew) {
      const existingDoc = await collection.doc(entry.id).get()
      if (existingDoc.exists) {
        throw new Error('You already have an entry for this day!')
      }
    }
  },
  async addEntry({ dispatch, rootState }, entryForm) {
    const doc = await dispatch('prepareInsert', entryForm)
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('entries')
    await dispatch('validate', { entry: doc, collection, isNew: true })
    await collection.doc(doc.id).set(doc)
    dispatch('authentication/updateReminderState', doc.id, { root: true })
    return doc
  },
  async updateEntry({ dispatch, rootState }, entryForm) {
    const doc = await dispatch('prepareInsert', entryForm)
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('entries')
    await dispatch('validate', { entry: doc, collection, isNew: false })
    await collection.doc(doc.id).set(doc)
    return doc
  },
  async deleteEntry({ rootState }, entryId) {
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('entries')
    await collection.doc(entryId).delete()
    return 'ok'
  },
  async exportToXLSX(
    { dispatch, state, rootGetters },
    { startDate, endDate, currency }
  ) {
    let promises = []
    const rows = []
    const headerRow = [{ value: 'Date', type: 'string' }]
    const categories = rootGetters['categories/orderedCategories']
    categories.forEach(category => {
      headerRow.push({ value: category.name, type: 'string' })
    })
    rows.push(headerRow)
    for (let idx = 0; idx < state.entries.length; ++idx) {
      const entry = state.entries[idx]
      if (startDate && dayjs(entry.date).isBefore(startDate)) continue
      if (endDate && dayjs(entry.date).isAfter(endDate)) continue
      const promise = dispatch(
        'currencies/getExchangeRateOn',
        {
          currency,
          date: entry.date
        },
        { root: true }
      ).then(rate => {
        const row = [
          {
            value: entry.id,
            type: 'string'
          }
        ]
        categories.forEach(category => {
          const dataPoint = category.isAsset
            ? entry.assets.find(asset => asset.categoryId === category.id)
            : entry.liabilities.find(
                liability => liability.categoryId === category.id
              )
          const baseValue = dataPoint
            ? dataPoint.amount / dataPoint.baseRate
            : 0
          row.push({ value: baseValue * rate, type: 'number' })
        })
        rows.push(row)
      })
      promises.push(promise)
      if (promises.length >= 20) {
        await Promise.all(promises)
        promises = []
      }
    }
    await Promise.all(promises)
    const exportConfig = {
      filename: `nutworth export ${dayjs().format('YYYY-MM-DD')}`,
      sheet: {
        data: rows
      }
    }
    zipcelx().then(exportXLSX => {
      exportXLSX(exportConfig)
    })
  },
  clearAllEntries() {
    return firebase.functions().httpsCallable('clearAllEntries')()
  },
  cleanup({ commit }) {
    if (clearEntryListener) clearEntryListener()
    commit('clearEntries')
  }
}
