// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

// Init firebase first
import '@/firebase/init'
import '@/firebase/authentication'

import Vue from 'vue'
import Fragment from 'vue-fragment'
import Buefy from 'buefy'
import VueScrollTo from 'vue-scrollto'
import Multiselect from 'vue-multiselect'
import VueCurrencyInput from 'vue-currency-input'
import AsyncComputed from 'vue-async-computed'

import CurrencyOutput from '@/components/CurrencyOutput.vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/misc/register-service-worker'
import '@/misc/handle-network-status'
import '@/misc/handle-apple-install-prompt'
import 'pwacompat/pwacompat.min'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/icons'

Vue.use(AsyncComputed)
Vue.use(Fragment.Plugin)
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})
Vue.component('MultiSelect', Multiselect)
Vue.component('CurrencyOutput', CurrencyOutput)
Vue.use(VueScrollTo, {
  offset: -85
})
Vue.use(VueCurrencyInput)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
