import Vue from 'vue'
import migrate from './categories.migrations'

export default {
  setLoadingCategories(state, value) {
    state.loadingCategories = value
  },
  loadCategory(state, category) {
    Vue.set(state.categories, category.id, migrate(category, state))
  },
  removeCategory(state, categoryId) {
    Vue.delete(state.categories, categoryId)
  },
  clearCategories(state) {
    Object.keys(state.categories).forEach(categoryId => {
      Vue.delete(state.categories, categoryId)
    })
  },
  linkOffsets({ categories }) {
    const offsetMap = {}
    Object.values(categories).forEach(category => {
      Vue.delete(category, 'offsetIds')
      if (category.offsetLoanId) {
        if (!(category.offsetLoanId in offsetMap)) {
          offsetMap[category.offsetLoanId] = []
        }
        offsetMap[category.offsetLoanId].push(category.id)
      }
    })
    Object.entries(offsetMap).forEach(([categoryId, offsetIds]) => {
      if (categories[categoryId]) {
        Vue.set(categories[categoryId], 'offsetIds', offsetIds)
      }
    })
  }
}
