export default {
  setNetworkOnline(state, value) {
    state.networkOnLine = value
  },
  setSWRegistrationForNewContent(state, value) {
    state.SWRegistrationForNewContent = value
  },
  setShowAddToHomeScreenModalForApple(state, value) {
    state.showAddToHomeScreenModalForApple = value
  },
  setRefreshingApp(state, value) {
    state.refreshingApp = value
  },
  setInTWA(state) {
    state.isTWA = true
    sessionStorage.setItem('isTWA', true)
  },
  setActiveNav(state, value) {
    state.activeNav = value
  }
}
