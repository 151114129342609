import { filter, orderBy } from 'lodash'
import dayjs from '@/dayjs'

export default {
  noGoals({ goals }) {
    return !Object.keys(goals).length
  },
  anyGoals({ goals }) {
    return Object.keys(goals).length
  },
  orderedGoals({ goals }) {
    return orderBy(Object.values(goals), ({ goalDate }) => goalDate)
  },
  pastGoals({ goals }) {
    return filter(Object.values(goals), ({ goalDate }) =>
      dayjs(goalDate).isBefore()
    )
  },
  futureGoals({ goals }) {
    return filter(Object.values(goals), ({ goalDate }) =>
      dayjs(goalDate).isAfter()
    )
  },
  getActiveGoal: (state, { orderedGoals }) => date => {
    const parsedDate = dayjs(date)
    if (!orderedGoals.length) {
      return null
    }
    const goal = orderedGoals.find(({ goalDate }) =>
      parsedDate.isSameOrBefore(goalDate)
    )
    if (goal) {
      return goal
    }
    return orderedGoals[orderedGoals.length - 1]
  }
}
