import { cloneDeep, sortBy } from 'lodash'

export default {
  getCategoryById: state => id => state.categories[id],
  getCategoryName: state => id => {
    if (state.categories[id]) return state.categories[id].name
    return 'Unknown'
  },
  getCategoriesByType: (state, { orderedCategories }) => typeId => {
    return orderedCategories.filter(({ type }) => type === typeId)
  },
  orderedCategories: ({ categories }, getters, { entries }, rootGetters) => {
    const lastEntry = entries.entries[0]
    const lastSize = {}

    switch (rootGetters['authentication/categoryOrder']) {
      case 'user':
        return sortBy(Object.values(categories), ({ weight }) =>
          weight === null ? 999 : weight
        )
      case 'size':
        lastEntry.assets.forEach(({ categoryId, amount, baseRate }) => {
          lastSize[categoryId] = amount / baseRate
        })
        lastEntry.liabilities.forEach(({ categoryId, amount, baseRate }) => {
          lastSize[categoryId] = amount / baseRate
        })
        return sortBy(
          Object.values(categories),
          ({ id }) => lastSize[id] || 0
        ).reverse()
      default:
        return sortBy(Object.values(categories), ({ name }) =>
          name.toLowerCase()
        )
    }
  },
  categoryWeight: (state, { orderedCategories }) => {
    const idWeightMap = {}
    orderedCategories.forEach(({ id }, idx) => {
      idWeightMap[id] = idx
    })
    return id => (id in idWeightMap ? idWeightMap[id] : 999)
  },
  atFreePlanLimit: (state, { orderedCategories }) =>
    orderedCategories.length >= 8,
  isOverLimit: () => count => count > 8,
  newCategoryForm: () => ({ name = '', isAsset = false, type = null }) => ({
    name,
    isAsset,
    type,
    customName: '',
    offsetLoanId: null
  }),
  editCategoryForm: (state, getters) => ({ id }) =>
    cloneDeep(getters.getCategoryById(id)),
  getCategoryLastCurrency: (state, getters, rootState) => {
    const lastEntry = rootState.entries.entries[0]
    return ({ id }) => {
      let target = null
      if (id) {
        const asset = lastEntry.assets.find(
          ({ categoryId }) => categoryId === id
        )
        if (asset) {
          target ||= asset
        }
        const liability = lastEntry.liabilities.find(
          ({ categoryId }) => categoryId === id
        )
        if (liability) {
          target ||= liability
        }
      }
      target ||= { currency: null, subunit: null }
      const { currency, subunit } = target
      return { currency, subunit }
    }
  },
  categoryNeedsConfig(state, { orderedCategories, getCategoryLastCurrency }) {
    const updateState = {}
    orderedCategories.forEach(category => {
      updateState[category.id] = false
      // Category has never been configured in simulator
      if (!('includeInAnalysis' in category)) {
        updateState[category.id] = true
        return
      }
      // Category isn't included in simulations
      if (!category.includeInAnalysis) {
        return
      }
      // Category doesn't have any currency information
      if (!('configCurrency' in category) || !category.configCurrency) {
        updateState[category.id] = true
        return
      }
      // Category has currency information, but it disagrees with
      // the latest holding's currency information for that category
      if (category.flatChanges.length || category.hasCap) {
        const { currency, subunit } = getCategoryLastCurrency(category)
        // Category isn't included in latest holding
        if (currency === null) {
          return
        }
        if (
          category.configCurrency.currency !== currency ||
          category.configCurrency.subunit !== subunit
        ) {
          updateState[category.id] = true
        }
      }
    })
    return ({ id }) => updateState[id]
  }
}
