import { register } from 'register-service-worker'

import store from '@/store'

async function removeOldServiceWorker() {

  // Get the current registration
  const registration = await navigator.serviceWorker.register('service-worker.js.*');

  // Unregister the previous service worker, if any
  registration.unregister().then(() => {   

      // Unregister the previous service worker clients
      registration.clients.matchAll({ type: 'window' }).then(clients => {
        clients.forEach(client => client.unregister());
      });

  });

}

fetch('service-worker.js?version=3')
  .then(function(response) {
    if (response.status === 404) {
      removeOldServiceWorker();
    }
  })

const version = 'v3' // Update this version string whenever you make changes to the service worker.  Also update in /public/service-worker.js
const serviceWorkerUrl = `/service-worker.js?version=${version}`

if (process.env.NODE_ENV === 'production') {
  register(serviceWorkerUrl, {
    ready() {
      console.log('Service worker is active.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      store.commit(`app/setSWRegistrationForNewContent`, reg)
      console.log('New content is available; please refresh.')

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
          registration.active.postMessage('skipWaiting')
        })
      }
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

if ('serviceWorker' in navigator) {
  // Force an immediate update of the service worker
  navigator.serviceWorker.register(serviceWorkerUrl).then(registration => {
    registration.update()
  })

  let refreshing = false
  // This is triggered when a new service worker take over
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) return
    refreshing = true

    window.location.reload()
  })
}
