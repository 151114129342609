import { isNil } from 'lodash'

export default {
  newContentAvailable: state => !isNil(state.SWRegistrationForNewContent),
  entriesActive({ activeNav }) {
    return activeNav === 'entries'
  },
  analyzeActive({ activeNav }) {
    return activeNav === 'analyze'
  },
  projectionsActive({ activeNav }) {
    return activeNav === 'projections'
  },
  shareActive({ activeNav }) {
    return activeNav === 'share'
  },
  adminActive({ activeNav }) {
    return activeNav === 'admin'
  }
}
