import { findIndex } from 'lodash'
import Vue from 'vue'

export default {
  setActiveCurrency(state, value) {
    state.activeCurrency = value
  },
  addCurrency: (state, currency) => state.currencies.push(currency),
  setLoadingCurrencies(state, value) {
    state.loadingCurrencies = value
  },
  updateCurrency(state, currency) {
    const index = findIndex(state.currencies, ({ id }) => id === currency.id)
    if (~index) {
      state.currencies[index] = currency
    }
  },
  removeCurrency(state, currencyId) {
    const index = findIndex(state.currencies, ({ id }) => id === currencyId)
    if (~index) {
      state.currencies.splice(index, 1)
    }
  },
  cacheRate(state, { currency, date, rate }) {
    if (!state.rateCache[currency]) {
      state.rateCache[currency] = {}
    }
    state.rateCache[currency][+date] = rate
  },
  clearCurrencies(state) {
    state.currencies = []
  },
  setSubunitPreferences(state, preferences) {
    Vue.set(state, 'subunitPreferences', preferences)
  }
}
