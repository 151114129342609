import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import dayjs from '@/dayjs'
import router from '@/router'
import { createNewUserProfile, saveUserProfile } from '@/misc/helpers'
import UsersDB from '@/firebase/users-db'
import { setAnalyticsAttributes } from '@/misc/analytics'

let clearProfileMonitor
let signupCache

function migrate(userRecord) {
  if (!('preferences' in userRecord)) {
    userRecord.preferences = {}
  }
  const { preferences } = userRecord
  if (!('maxSF' in preferences)) {
    preferences.maxSF = 20
  }
  if (!('useDecimals' in preferences)) {
    preferences.useDecimals = true
  }
  if (!('categoryOrder' in preferences)) {
    preferences.categoryOrder = 'user'
  }
  if (!('showExchangeRate' in preferences)) {
    preferences.showExchangeRate = false
  }
}

export default {
  /**
   * Callback fired when registering
   */
  register(
    { commit, dispatch },
    { email, password, plan, currency, coupon = null }
  ) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        return createNewUserProfile(user, {
          plan,
          billingCurrency: currency,
          coupon
        })
      })
      .then(userRecord => {
        commit('setUser', userRecord)
        dispatch('plan/checkPaymentStatus', userRecord, { root: true })
        // For people who have paid in AUD, let's give them AUD as the default
        // once they're inside
        if (userRecord.billingCurrency === 'aud') {
          dispatch('currencies/setDisplayCurrency', 'AUD', { root: true })
        }
        return userRecord
      })
  },

  reauthenticate({ state, dispatch }, dialogOptions) {
    if (state.hasPassword) {
      return dispatch('reauthenticateWithPassword', dialogOptions)
    }
    switch (state.authProvider) {
      case 'google.com':
        return new Promise((resolve, reject) => {
          Dialog.confirm({
            ...dialogOptions,
            onConfirm: () => {
              firebase
                .auth()
                .currentUser.reauthenticateWithPopup(
                  new firebase.auth.GoogleAuthProvider()
                )
                .then(resolve)
            },
            onCancel: () => {
              reject(new Error('cancel'))
            }
          })
        })
      default:
        throw new Error('Please contact support. Code 1722')
    }
  },

  /*
   * Refresh the auth credentials for the current user
   */
  reauthenticateWithPassword({ rootState }, dialogOptions) {
    return new Promise((resolve, reject) => {
      Dialog.prompt({
        ...dialogOptions,
        inputAttrs: {
          placeholder: 'Password',
          type: 'password',
          autocomplete: 'current-password'
        },
        onConfirm: password => {
          const credential = firebase.auth.EmailAuthProvider.credential(
            rootState.authentication.user.email,
            password
          )
          firebase
            .auth()
            .currentUser.reauthenticateWithCredential(credential)
            .then(
              () => resolve(),
              () => reject(new Error('invalid-password'))
            )
        },
        onCancel: () => reject(new Error('cancel'))
      })
    })
  },

  signInWithGoogle(
    store,
    { plan = 'starter', currency = 'usd', coupon = null } = {}
  ) {
    const provider = new firebase.auth.GoogleAuthProvider()
    signupCache = { plan, currency, coupon }
    return firebase.auth().signInWithPopup(provider)
  },

  /**
   * Change plan
   */
  changePlan({ commit, dispatch, state }, { plan, currency, coupon }) {
    const doc = {
      ...state.user,
      plan,
      billingCurrency: currency,
      paymentState: 'pending',
      isNewPlan: true,
      coupon
    }
    // Save, triggers will take care of the rest
    return saveUserProfile(doc).then(() => {
      // Eagerly setUser with temporary data so /welcome knows what to do
      commit('setUser', doc)
      dispatch('plan/checkPaymentStatus', doc, { root: true })
    })
  },

  /*
   * If dateStr > user.lastEntryOn || user.lastEntryOn is null
   *
   * Update:
   *
   * - lastEntryOn
   * - reminderState
   * - reminderDueAt
   */
  updateReminderState({ state }, dateStr) {
    if (!state.user.lastEntryOn || dateStr > state.user.lastEntryOn) {
      const reminderState = 'not-reminded'
      let reminderDueAt = dayjs()
      switch (state.user.remindAfter) {
        case 'week':
          reminderDueAt = reminderDueAt.add(1, 'week')
          break
        case 'fortnight':
          reminderDueAt = reminderDueAt.add(2, 'week')
          break
        case 'month':
          reminderDueAt = reminderDueAt.add(1, 'month')
          break
        case 'quarter':
          reminderDueAt = reminderDueAt.add(3, 'month')
          break
        default:
          reminderDueAt = reminderDueAt.add(1, 'month')
      }
      const doc = {
        ...state.user,
        lastEntryOn: dateStr,
        reminderState,
        reminderDueAt: reminderDueAt.toDate()
      }
      return saveUserProfile(doc)
    }
    return false
  },

  /*
   * Update reminder preference
   */
  updateReminderPreference({ state }, remindAfter) {
    const doc = {
      ...state.user,
      remindAfter
    }
    return saveUserProfile(doc)
  },

  /*
   * Update misc preferences
   */
  updatePreferences({ state }, preferences) {
    const doc = {
      ...state.user,
      preferences: {
        ...state.user.preferences,
        ...preferences
      }
    }
    return saveUserProfile(doc)
  },

  /**
   * Callback fired when user login
   */
  login: async ({ commit, dispatch }, firebaseAuthUser) => {
    let firstRun = true
    if (clearProfileMonitor !== undefined) {
      clearProfileMonitor()
      clearProfileMonitor = undefined
    }
    commit('setLoginProvider', firebaseAuthUser.providerData)
    clearProfileMonitor = await new UsersDB().monitor(
      firebaseAuthUser.uid,
      async userRecord => {
        // They don't have an account, so we create one (sso signup)
        if (isNil(userRecord)) {
          // Load plan & currency from cache if available
          let plan = 'starter'
          let billingCurrency = 'usd'
          let coupon = null
          if (signupCache) {
            ;({ plan, currency: billingCurrency, coupon } = signupCache)
            signupCache = null
            // fall back to sniffing navigator for AUD
          } else if (navigator.language === 'en-AU') {
            billingCurrency = 'aud'
          }
          return createNewUserProfile(firebaseAuthUser, {
            plan,
            billingCurrency,
            coupon
          })
        }
        migrate(userRecord)
        if (firstRun) {
          let shouldSave = false
          // Sync profile attributes async
          // email Verification status
          if (userRecord.emailVerified !== firebaseAuthUser.emailVerified) {
            userRecord.emailVerified = firebaseAuthUser.emailVerified
            shouldSave = true
          }
          // Email address
          if (userRecord.email !== firebaseAuthUser.email) {
            userRecord.email = firebaseAuthUser.email
            shouldSave = true
          }
          // Save if needed
          if (shouldSave) {
            saveUserProfile(userRecord).then(() => {
              // If they've verified but that's not reflected in the database,
              // try and sync that here
              if (firebaseAuthUser.emailVerified && userRecord.emailInvalid) {
                dispatch('onEmailVerified').catch(() => {})
              }
            })
            // If they've verified but that's not reflected in the database,
            // try and sync that here
          } else if (
            firebaseAuthUser.emailVerified &&
            userRecord.emailInvalid
          ) {
            dispatch('onEmailVerified').catch(() => {})
          }

          // Load available currency list
          dispatch('currencies/loadCurrencies', null, { root: true })
          // Load available category list
          dispatch('categories/loadCategories', null, { root: true })
          // Load all user entry data
          dispatch('entries/loadEntries', null, { root: true })
          // Load goal list
          dispatch('goals/loadGoals', null, { root: true })
          // Load scenarios
          dispatch('scenarios/initialize', firebaseAuthUser.uid, { root: true })

          // Log to analytics
          setAnalyticsAttributes({
            account_type: userRecord.plan,
            paid_account: userRecord.plan !== 'starter'
          })

          // We only want to do this once or we end up with
          // issues if firebaseAuthUser is stale
          firstRun = false
        }
        // Persist the state
        commit('setUser', userRecord)
        dispatch('plan/checkPaymentStatus', userRecord, { root: true })
        return 'ok'
      }
    )
  },

  /*
   * Send an email verification
   */
  async sendEmailVerification() {
    const result = await firebase
      .functions()
      .httpsCallable('sendEmailValidation')()
    return result.data.status
  },

  /*
   * Update userRecord on verify
   */
  async onEmailVerified({ state, commit }) {
    const result = await firebase
      .functions()
      .httpsCallable('onEmailValidated')()
    const doc = {
      ...state.user,
      emailVerified: true,
      emailInvalid: false
    }
    commit('setUser', doc)
    return result.data.status
  },

  /*
   * Reset password email
   */
  async resetPassword(store, email) {
    const result = await firebase.functions().httpsCallable('resetPassword')({
      email
    })
    return result.data.status
  },

  /**
   * User changes email address
   */
  changeEmailAddress({ commit, state, dispatch }, newEmail) {
    const { currentUser } = firebase.auth()
    return currentUser
      .updateEmail(newEmail)
      .then(() => dispatch('sendEmailVerification'))
      .then(async () => {
        const doc = {
          ...state.user,
          email: newEmail,
          emailVerified: false
        }
        await saveUserProfile(doc)
        commit('setUser', doc)
      })
      .then(() => {
        Toast.open('Check your email for a confirmation link.')
      })
  },

  /**
   * Callback fired when user logs out
   */
  onLogout: ({ commit, dispatch, state }) => {
    if (state.user) {
      dispatch('currencies/cleanup', null, { root: true })
      dispatch('categories/cleanup', null, { root: true })
      dispatch('scenarios/cleanup', null, { root: true })
      dispatch('goals/cleanup', null, { root: true })
      dispatch('entries/cleanup', null, { root: true })

      const currentRouter = router.app.$route
      if (!(currentRouter.meta && currentRouter.meta.authNotRequired)) {
        router.push('/login').catch(() => {})
      }

      if (clearProfileMonitor !== undefined) {
        clearProfileMonitor()
        clearProfileMonitor = undefined
      }
    }
    commit('setUser', null)
  },
  // Action to sign the user out from firebase
  logout() {
    setAnalyticsAttributes({})
    return firebase.auth().signOut()
  },

  /**
   * Mark a user's paymentState immediately as pending
   */
  markPending({ state, commit }) {
    commit('setUser', {
      ...state.user,
      paymentState: 'pending'
    })
  },

  /**
   * Delete a user's account
   */
  cancelAccount: ({ dispatch }) => {
    firebase
      .auth()
      .currentUser.delete()
      .then(() => dispatch('onLogout', null))
      .then(() => {
        Toast.open("We're sorry to see you go.")
      })
  }
}
