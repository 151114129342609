<template lang="pug">
.verification-nag(v-show="shouldShow")
  | You need to verify your email address.
  b-button.is-small(@click="resendCode" :loading="isSending" :disabled="hasSent")
    | {{hasSent ? "Sent!" : "RESEND EMAIL"}}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dayjs from '@/dayjs'

export default {
  data: () => ({
    timeThreshold: dayjs().subtract(1, 'd'),
    isSending: false,
    hasSent: false
  }),
  computed: {
    ...mapState('authentication', ['user']),
    shouldShow() {
      return (
        this.user &&
        !this.user.emailInvalid &&
        this.timeThreshold.isAfter(this.user.createTimestamp)
      )
    }
  },
  methods: {
    ...mapActions('authentication', ['sendEmailVerification']),
    resendCode() {
      this.isSending = true
      this.sendEmailVerification()
        .then(() => {
          this.isSending = false
          this.hasSent = true
        })
        .catch(err => {
          this.isSending = false
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            duration: 5000
          })
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.verification-nag
  padding: 0.5rem 2rem
  background: #333
  color: white
  font-weight: bold
  display: flex
  align-items: center
  justify-content: center
  button
    margin-left: 0.5rem
</style>
