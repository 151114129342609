import localforage from 'localforage'
import { cloneDeep } from 'lodash'
import { currencyWithSubunit } from '@/misc/helpers'

const defaultAssets = [
  { typeId: 'cash', name: 'Cash', enabled: true },
  { typeId: 'retirement', name: 'Retirement Savings', enabled: false },
  { typeId: 'equity', name: 'Stock Portfolio', enabled: false },
  { typeId: 'property', name: 'Property Portfolio', enabled: false },
  { typeId: 'custom', name: 'Other', enabled: false }
]

const defaultLiabilities = [
  { typeId: 'credit', name: 'Credit Card Debt', enabled: false },
  { typeId: 'mortgage', name: 'Mortgage', enabled: false },
  { typeId: 'loan', name: 'Personal Loans', enabled: false },
  { typeId: 'student', name: 'Student Loans', enabled: false },
  { typeId: 'custom', name: 'Other', enabled: false }
]

const version = 2

export default {
  async clearStorage({ commit }) {
    commit('writeAssets', defaultAssets)
    commit('writeLiabilities', defaultLiabilities)
  },
  async getAssets({ state, commit }) {
    // Default: check the state
    let { assets } = state
    // Next: check localstorage for saved
    if (!assets) {
      const storedAssets = await localforage.getItem('calculator/assets')
      if (storedAssets && storedAssets.version === version) {
        assets = storedAssets.data
      }
    }
    // Fall back to default assets
    if (!assets) {
      assets = defaultAssets
    }
    // Save to cache for next time around
    commit('writeAssets', assets)
    return cloneDeep(assets)
  },
  async getLiabilities({ state, commit }) {
    // Default: check the state
    let { liabilities } = state
    // Next: check localstorage for saved
    if (!liabilities) {
      const storedLiabilities = await localforage.getItem(
        'calculator/liabilities'
      )
      if (storedLiabilities && storedLiabilities.version === version) {
        liabilities = storedLiabilities.data
      }
    }
    // Fall back to default liabilities
    if (!liabilities) {
      liabilities = defaultLiabilities
    }
    // Save to cache for next time around
    commit('writeLiabilities', liabilities)
    return cloneDeep(liabilities)
  },
  async syncAssets({ commit }, assets) {
    await localforage.setItem('calculator/assets', {
      version,
      data: assets
    })
    commit('writeAssets', cloneDeep(assets))
    return true
  },
  async syncLiabilities({ commit }, liabilities) {
    await localforage.setItem('calculator/liabilities', {
      version,
      data: liabilities
    })
    commit('writeLiabilities', cloneDeep(liabilities))
    return true
  },
  async importIntoAccount({ dispatch, state, rootGetters }) {
    // 1. Write Categories
    const categoryPromises = []
    state.assets.forEach(asset => {
      if (!asset.enabled) return
      categoryPromises.push(
        dispatch(
          'categories/saveCategory',
          {
            name: asset.name,
            isAsset: true,
            type: asset.typeId,
            customName: ''
          },
          { root: true }
        )
      )
    })
    state.liabilities.forEach(liability => {
      if (!liability.enabled) return
      categoryPromises.push(
        dispatch(
          'categories/saveCategory',
          {
            name: liability.name,
            isAsset: false,
            type: liability.typeId,
            customName: ''
          },
          { root: true }
        )
      )
    })
    await Promise.all(categoryPromises)
    // 2. Write Entry
    const entryForm = rootGetters['entries/newEntryForm']()
    entryForm.note = 'Imported from the simple net worth calculator.'
    // Assets
    await Promise.all(
      state.assets.map(async asset => {
        if (!asset.enabled) return
        // Get the created category
        const categories = rootGetters['categories/getCategoriesByType'](
          asset.typeId
        )
        const category = categories[0]
        if (category) {
          // Add to the generated entry
          entryForm.assets.push({
            amount: asset.amount,
            baseRate: await dispatch(
              'currencies/getExchangeRateOn',
              {
                currency: currencyWithSubunit(asset.currency, asset.subunit),
                date: entryForm.date
              },
              { root: true }
            ),
            categoryId: category.id,
            currency: asset.currency,
            subunit: asset.subunit,
            customRate: false
          })
        }
      })
    )
    // Liabilities
    await Promise.all(
      state.liabilities.map(async liability => {
        if (!liability.enabled) return
        // Get the created category
        const categories = rootGetters['categories/getCategoriesByType'](
          liability.typeId
        )
        // There can be 2x custom categories, we need the second one in that
        // case
        const category = categories[categories.length - 1]
        if (category) {
          // Add to the generated entry
          entryForm.liabilities.push({
            amount: liability.amount,
            baseRate: await dispatch(
              'currencies/getExchangeRateOn',
              {
                currency: currencyWithSubunit(
                  liability.currency,
                  liability.subunit
                ),
                date: entryForm.date
              },
              { root: true }
            ),
            categoryId: category.id,
            currency: liability.currency,
            subunit: liability.subunit,
            customRate: false
          })
        }
      })
    )
    // Persist
    await this.dispatch('entries/addEntry', entryForm, { root: true })
    // 3. Clear Storage
    dispatch('clearStorage')
  }
}
