export default function migrate(scenario) {
  scenario.actions.forEach(action => {
    action.changes.forEach(change => {
      // Add indexation if missing
      if (!('indexation' in change)) {
        change.indexation = 'none'
        change.indexFactor = '0'
        change.indexPeriod = 'year'
      }
      // Add subunit if missing
      if (!('subunit' in change)) {
        change.subunit = null
      }
    })
  })
  return scenario
}
