import { get } from 'lodash'
import dayjs from '@/dayjs'
import UsersDB from '@/firebase/users-db'
import { formatDate } from '@/misc/filters'

/**
 * Create user settings data for a fresh user
 */
// eslint-disable-next-line
export const createNewUserProfile = async (firebaseAuthUser, attrs) => {
  const { email } = firebaseAuthUser.providerData[0]
  const userDb = new UsersDB()
  const user = {
    ...attrs,
    email,
    isNewPlan: true,
    emailVerified: firebaseAuthUser.emailVerified,
    paymentState: 'pending',
    integrations: {},
    preferences: {
      useDecimals: true,
      maxSF: 10
    }
  }

  return userDb.create(user, firebaseAuthUser.uid)
}

export const saveUserProfile = userDoc => {
  return new UsersDB().update(userDoc)
}

export function mapCons(list, fn) {
  const result = []
  for (let idx = 1; idx < list.length; ++idx) {
    result.push(fn(list[idx - 1], list[idx]))
  }
  return result
}

export function prepareEntry(entryForm) {
  // Documents are ID'd by date
  const date = dayjs(entryForm.date)
  if (!date.isValid) throw new Error('Invalid entry date!')
  const id = date.format('YYYY-MM-DD')
  // We pre-calculate and cache net figures in the base currency
  const assets = []
  const liabilities = []
  let netAssets = 0
  let netLiabilities = 0
  entryForm.assets.forEach(asset => {
    const baseAmount = asset.amount / asset.baseRate
    if (Number.isNaN(baseAmount))
      throw new Error(`Asset is missing exchange rate!`)
    assets.push(asset)
    netAssets += baseAmount
  })
  entryForm.liabilities.forEach(liability => {
    const baseAmount = liability.amount / liability.baseRate
    if (Number.isNaN(baseAmount))
      throw new Error(`Liability is missing exchange rate!`)
    liabilities.push(liability)
    netLiabilities += baseAmount
  })
  // Save
  return {
    id,
    assets,
    liabilities,
    note: entryForm.note,
    netAssets,
    netLiabilities,
    netWorth: netAssets - netLiabilities
  }
}

export function dateParser(date) {
  return dayjs(date).toDate()
}

export function dateFormatter(date) {
  return formatDate(date, 'short')
}

export function dateFormatterLong(date) {
  return formatDate(date, 'day')
}

export function currencyWithSubunit(currency, subunit) {
  if (subunit) {
    return `${currency}.${subunit}`
  }
  return currency
}

export function currencyInputFormatter(chosenCurrency, chosenSubunit) {
  if (!chosenCurrency) {
    return undefined
  }
  // Subunits can set a suffix
  const { suffix = null } = chosenSubunit || {}
  // Subunits or the currency itself can optionally set a prefix
  if (chosenSubunit && 'prefix' in chosenSubunit) {
    return { prefix: chosenSubunit.prefix, suffix }
  }
  if ('prefix' in chosenCurrency) {
    return { prefix: chosenCurrency.prefix, suffix }
  }
  // Begin default formatting rules
  const { type, id } = chosenCurrency
  // Fiat currencies have built in browser support
  if (type === 'fiat') {
    return id
  }
  // Crypto currencies we just prefix with the symbol for them
  if (type === 'crypto') {
    return { prefix: `${id.slice(1)} `, suffix }
  }
  // Some custom currencies have emoji for c1, we use the emoji as a prefix
  if (!id[0].match(/[A-Z]/)) {
    const symbol = [...id][0]
    return { prefix: `${symbol} `, suffix }
  }
  // Otherwise, we just have a nice unambiguous ID
  return { prefix: `${id} `, suffix }
}

export function getPreferences(state) {
  return get(state, ['authentication', 'user', 'preferences'], {
    maxSF: 20,
    useDecimals: true
  })
}
