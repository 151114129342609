import { find } from 'lodash'
import localforage from 'localforage'
import dayjs from '@/dayjs'
import firestore from '@/firebase/async-firestore'

let clearCurrencyListener
const rateCacheVersion = 1

export default {
  setDisplayCurrency({ state, commit }, currencyId) {
    const currencyRecord = find(state.currencies, ({ id }) => id === currencyId)
    if (!currencyRecord) throw new Error(`Invalid currency code ${currencyId}`)
    localStorage.setItem('displayCurrencyPreference', currencyId)
    commit('setActiveCurrency', currencyRecord)
  },
  async loadSubunitPreferences({ commit }) {
    const preferences = await localforage.getItem('subunits/preferences')
    if (preferences) {
      commit('setSubunitPreferences', preferences)
    }
  },
  setActiveSubunit({ state, commit }, subunit) {
    const { activeCurrency } = state
    if (!activeCurrency.subunits || !(subunit.id in activeCurrency.subunits)) {
      throw new Error('invalid-subunit')
    }
    const subunitPreferences = {
      ...state.subunitPreferences,
      [activeCurrency.id]: subunit.id
    }
    commit('setSubunitPreferences', subunitPreferences)
    localforage.setItem('subunits/preferences', subunitPreferences)
  },
  loadDefaultDisplayCurrency({ commit, getters }) {
    // Load the last one they used if saved
    const savedCurrency = localStorage.getItem('displayCurrencyPreference')
    let displayCurrency
    if (savedCurrency) {
      displayCurrency = getters.getCurrencyById(savedCurrency)
    }
    // Try infer from locale
    if (!displayCurrency) {
      displayCurrency = getters.getCurrencyById(getters.inferDisplayCurrency)
    }
    // Fall back to USD as a worst case
    if (!displayCurrency) {
      displayCurrency = getters.getCurrencyById('USD')
    }
    commit('setActiveCurrency', displayCurrency)
  },
  async loadCurrencies({ state, commit, dispatch }) {
    // Don't double load currencies
    if (clearCurrencyListener) {
      return
    }
    const db = await firestore()
    commit('setLoadingCurrencies', true)
    clearCurrencyListener = db.collection('currencies').onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          commit('addCurrency', change.doc.data())
        }
        if (change.type === 'modified') {
          commit('updateCurrency', change.doc.data())
        }
        if (change.type === 'removed') {
          commit('removeCurrency', change.doc.id)
        }
      })
      if (state.loadingCurrencies) {
        Promise.all([
          dispatch('loadDefaultDisplayCurrency'),
          dispatch('loadSubunitPreferences')
        ]).then(() => commit('setLoadingCurrencies', false))
      }
    })
  },
  async getExchangeRateOn({ commit, getters }, { currency, date }) {
    const [currencyId, currencyUnit] = currency.split('.', 2)
    // 1 USD is always 1 USD
    if (currencyId === 'USD')
      return getters.convert(1, currencyId, currencyUnit)
    const roundedDate = dayjs(date)
      .startOf('day')
      .toDate()
    // in-memory cache
    let cachedRate = getters.cachedRate(currencyId, roundedDate)
    if (cachedRate) {
      const rate = await cachedRate.rate
      return getters.convert(rate, currencyId, currencyUnit)
    }
    // localstorage cache
    try {
      cachedRate = await localforage.getItem(
        `rates/${currencyId}/${+roundedDate}`
      )
      if (cachedRate && cachedRate.version === rateCacheVersion) {
        commit('cacheRate', {
          currencyId,
          date: roundedDate,
          rate: new Promise(res => {
            res(cachedRate.rate)
          })
        })
        return getters.convert(cachedRate.rate, currencyId, currencyUnit)
      }
    } catch (err) {
      // continue without the localforage cache
    }
    // load from db
    const db = await firestore()
    const ratesCollection = db
      .collection('currencies')
      .doc(currencyId)
      .collection('rates')
    const ratePromise = ratesCollection
      .where('validFrom', '<=', roundedDate)
      .orderBy('validFrom', 'desc')
      .limit(1)
      .get()
      .then(querySnapshot => querySnapshot.docs[0])
      .then(doc => {
        if (doc) {
          return doc.data().rate
        }
        throw new Error('no-rate')
      })
    commit('cacheRate', { currencyId, roundedDate, rate: ratePromise })
    return ratePromise.then(rate => {
      localforage
        .setItem(`rates/${currencyId}/${+roundedDate}`, {
          date: +new Date(),
          rate,
          version: rateCacheVersion
        })
        .catch(() => {})
      return getters.convert(rate, currencyId, currencyUnit)
    })
  },
  cleanup({ commit }) {
    if (clearCurrencyListener) {
      clearCurrencyListener()
      clearCurrencyListener = undefined
    }
    commit('clearCurrencies')
  }
}
