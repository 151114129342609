import { cloneDeep, isEqual, pick } from 'lodash'
import firestore from '@/firebase/async-firestore'
import { regenerateCustomColors } from '@/misc/type_map'

let clearCategoryListener

export default {
  async loadCategories({ state, rootState, commit, dispatch }) {
    const db = await firestore()
    commit('setLoadingCategories', true)
    if (clearCategoryListener !== undefined) {
      clearCategoryListener()
      clearCategoryListener = undefined
    }
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('categories')
    clearCategoryListener = collection.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added' || change.type === 'modified') {
          commit('loadCategory', { ...change.doc.data(), id: change.doc.id })
        }
        if (change.type === 'removed') {
          commit('removeCategory', change.doc.id)
        }
      })
      commit('linkOffsets')
      if (state.loadingCategories) {
        commit('setLoadingCategories', false)
      }
      dispatch('regenerateColorPalette')
    })
  },
  regenerateColorPalette({ state }) {
    const customTypes = new Set()
    Object.values(state.categories).forEach(category => {
      if (category.type === 'custom' && category.customName) {
        customTypes.add(category.customName)
      }
    })
    regenerateCustomColors(Array.from(customTypes))
  },
  cleanup({ commit }) {
    if (clearCategoryListener) clearCategoryListener()
    commit('clearCategories')
  },
  async saveCategory({ dispatch }, form) {
    const changes = await dispatch('prepareInsert', form)
    await Promise.all(changes.map(({ model, ref }) => ref.set(model)))
    return { id: changes[0].ref.id, ...changes[0].model }
  },
  async deleteCategory({ state, rootState }, categoryId) {
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('categories')
    const ref = collection.doc(categoryId)
    await ref.delete()
    // Clean up anything where offsetLoanId === categoryId
    const promises = []
    Object.values(state.categories).forEach(({ id, offsetLoanId }) => {
      if (offsetLoanId === categoryId) {
        promises.push(collection.doc(id).update({ offsetLoanId: null }))
      }
    })
    await Promise.all(promises)
  },
  async prepareInsert({ rootState, state, getters }, form) {
    const model = cloneDeep(form)
    if (!('weight' in model)) {
      model.weight = getters.orderedCategories.length
    }
    const db = await firestore()
    // Check for uniqueness on model.name
    Object.values(state.categories).forEach(({ id, isAsset, name }) => {
      if (id === model.id) {
        return
      }
      if (isAsset === model.isAsset && name === model.name) {
        throw new Error('not-unique')
      }
    })
    const changes = []
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('categories')
    const ref = 'id' in model ? collection.doc(model.id) : collection.doc()
    changes.push({ model, ref })
    // Sync offset account data
    Object.values(state.categories).forEach(otherCategory => {
      if (otherCategory.id === ref.id) {
        return
      }
      // If we have offset IDs set on the model to be saved
      if (model.offsetIds && model.offsetIds.length) {
        // and we have found the matching category
        if (~model.offsetIds.indexOf(otherCategory.id)) {
          // and it is not linked to this category
          if (otherCategory.offsetLoanId !== ref.id) {
            // link it
            changes.push({
              model: { ...otherCategory, offsetLoanId: ref.id },
              ref: collection.doc(otherCategory.id)
            })
          }
          // and continue
          return
        }
      }
      // if the other category is linked to this category but isn't matching
      if (otherCategory.offsetLoanId === ref.id) {
        // unlink it
        changes.push({
          model: { ...otherCategory, offsetLoanId: null },
          ref: collection.doc(otherCategory.id)
        })
      }
    })
    delete model.offsetIds
    return changes
  },
  async saveOrder({ rootState, getters, commit }, categories) {
    const db = await firestore()
    const collection = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('categories')
    const batch = db.batch()
    const categoryWeights = categories
      .map(({ weight = null }, idx) => (weight === null ? idx : weight))
      .sort((a, b) => a - b)
    // Fix any duplicate weights
    categoryWeights.forEach((weight, idx) => {
      if (idx > 0) {
        const lastWeight = categoryWeights[idx - 1]
        if (lastWeight >= weight) {
          categoryWeights[idx] = lastWeight + 1
        }
      }
    })
    // Persist
    categories.forEach((category, idx) => {
      if (category.weight !== categoryWeights[idx]) {
        const ref = collection.doc(category.id)
        batch.set(ref, { weight: categoryWeights[idx] }, { merge: true })
        // Eager load into vuex
        commit('loadCategory', {
          ...getters.getCategoryById(category.id),
          weight: categoryWeights[idx]
        })
      }
    })
    return batch.commit()
  },
  async linkToSharesight({ rootState }, { category, portfolio }) {
    const db = await firestore()
    const ref = db
      .collection('users')
      .doc(rootState.authentication.user.id)
      .collection('categories')
      .doc(category.id)
    await ref.update({
      integration: {
        type: 'sharesight',
        portfolioId: portfolio.id,
        portfolioName: portfolio.name,
        currency: portfolio.currency
      }
    })
  },
  async saveProjectionData({ rootState, getters }, changes) {
    const db = await firestore()
    const batch = db.batch()
    let anyChange = false
    changes.forEach(
      ({
        id,
        includeInAnalysis,
        percentageChange,
        flatChanges,
        snowballCategoryId,
        payInterestFrom,
        hasCap,
        cappedAmount,
        configCurrency
      }) => {
        const category = getters.getCategoryById(id)
        const patch = {
          includeInAnalysis,
          percentageChange,
          flatChanges,
          snowballCategoryId,
          payInterestFrom,
          hasCap,
          cappedAmount
        }
        if (configCurrency) {
          patch.configCurrency = configCurrency
        }
        const oldData = pick(category, [
          'includeInAnalysis',
          'percentageChange',
          'flatChanges',
          'snowballCategoryId',
          'payInterestFrom',
          'hasCap',
          'cappedAmount',
          'configCurrency'
        ])
        const ref = db
          .collection('users')
          .doc(rootState.authentication.user.id)
          .collection('categories')
          .doc(category.id)
        if (!isEqual(patch, oldData)) {
          anyChange = true
          batch.set(ref, patch, { merge: true })
        }
      }
    )
    if (anyChange) {
      await batch.commit()
    }
  },
  async lazyMigrate({ state, getters, dispatch }) {
    const promises = []
    Object.values(state.categories).forEach(category => {
      // Category isn't being used with the simulator
      if (!category.includeInAnalysis) {
        return
      }
      const localCategory = getters.editCategoryForm(category)
      if (!('configCurrency' in category)) {
        localCategory.configCurrency = getters.getCategoryLastCurrency(category)
        // Category doesn't have a matching holding in the last entry
        if (localCategory.configCurrency.currency === null) {
          return
        }
        promises.push(dispatch('saveCategory', localCategory))
      }
    })
    await Promise.all(promises)
  }
}
