import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyDNkbeI5S9VKwfCUSbpFQ0RI8JHGWIW3gY',
  authDomain: 'app.nutworth.com',
  databaseURL: 'https://nutworth-prod.firebaseio.com',
  projectId: 'nutworth-prod',
  storageBucket: 'nutworth-prod.appspot.com',
  messagingSenderId: '977516920655',
  appId: '1:977516920655:web:0919a862d80f68eb654f3a'
  // measurementId: 'G-565P8DE9W6'
}

firebase.initializeApp(config)

// Development mode - use functions emulator
if ('webpackHotUpdate' in window) {
  firebase.functions().useFunctionsEmulator('http://localhost:5000')
}
