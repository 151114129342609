<template>
  <div id="app">
    <div v-if="$route.name && !$route.meta.hasOwnLayout" id="core-layout">
      <nav-bar></nav-bar>
      <div class="main-wrapper">
        <verification-nag v-if="userNeedsEmailVerification" />
        <router-view />
      </div>
      <apple-add-to-home-screen-modal
        v-if="isUserLoggedIn && showAddToHomeScreenModalForApple"
        class="apple-add-to-home-screen-modal"
        @close="closeAddToHomeScreenModalForApple(false)"
      >
      </apple-add-to-home-screen-modal>
    </div>
    <div v-if="$route.name && $route.meta.hasOwnLayout" id="own-layout">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import VerificationNag from '@/components/VerificationNag'
import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'
import store from '@/store'
import { pageImpression } from '@/misc/analytics'

export default {
  components: {
    NavBar,
    AppleAddToHomeScreenModal,
    VerificationNag
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapGetters('authentication', [
      'isUserLoggedIn',
      'userNeedsEmailVerification'
    ]),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  watch: {
    newContentAvailable() {
      if (this.newContentAvailable) {
        this.serviceWorkerSkipWaiting()
      }
    }
  },
  methods: mapActions('app', [
    'closeAddToHomeScreenModalForApple',
    'serviceWorkerSkipWaiting'
  ]),
  metaInfo: {
    titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`,
    changed(metaInfo) {
      const screenName = metaInfo.titleChunk
      pageImpression({
        // Note - this information is currently ignored,
        // but should we upgrade our analytics in the future
        // it could become useful again
        app_name: store.state.app.isTWA ? 'twa' : 'web',
        screen_name: screenName
      })
    }
  }
}
</script>

<style lang="scss">
@import '~bulma/sass/utilities/_all';

// Set your colors
$primary: #43ae43;
$primary-invert: findColorInvert($primary);
$twitter: #2e8e8c;
$twitter-invert: findColorInvert($twitter);
$success: #308e2e;
$success-invert: findColorInvert($success);

// Breakpoint
$navbar-breakpoint: $tablet;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  ),
  'twitter': (
    $twitter,
    $twitter-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Pricing table
$pricing-item-color: #555;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~bulma-pricingtable';
@import '~buefy/src/scss/buefy';
</style>

<style lang="sass">
// Custom styles
html, body
  min-height: 100%
  height: 100%
main
  min-height: 100%
  display: flex
  flex-direction: column
#app, #core-layout, #own-layout
  flex-grow: 1
  display: flex
  flex-direction: column
.main-wrapper
  flex-grow: 1
  background: #fafafa
  display: flex
  flex-direction: column
  > *
    width: 100%
.notices
  top: 60px
.icon.is-large
  svg
    height: 100%
    width: 100%
.announcekit-frame-wrapper
  min-width: 360px !important
.maybe-mobile-section
  position: absolute
  top: 0
  left: 0
  right: 0
  z-index: 40
  min-height: 100%
.auth-button
  display: flex
  height: 2.5rem
  padding: 0
  padding-right: 0.75rem
  border-radius: 3px
  background: white !important
  margin-bottom: 1rem
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  .icon-container
    width: 2.5rem
    height: 2.5rem
    display: flex
    align-items: center
    justify-content: center
  .text
    flex-grow: 1
    display: flex
@media screen and (max-width: 768px)
  .section
    padding: 1.5rem 0.75rem
  .has-spaced-navbar-fixed-top
    padding-top: 3.25rem
  .modal .animation-content .modal-card
    padding: 0.25rem
@media screen and (min-width: 769px)
  .maybe-mobile-section
    position: static
    overflow: visible
    z-index: auto
    min-height: auto
</style>
