import { isEqual } from 'lodash'
import { diff } from 'deep-object-diff'

export default {
  setUser(state, value) {
    if (state.user && value) {
      const changes = diff(state.user, value)
      // Skip full update when only the updateTimestamp is changing
      // to prevent un-necessary reloads
      if (isEqual(Object.keys(changes), ['updateTimestamp'])) {
        state.user.updateTimestamp = value.updateTimestamp
        return
      }
    }
    state.user = value
  },
  setLoginProvider(state, providerData) {
    state.canChangeEmail = true
    state.hasPassword = false
    providerData.forEach(({ providerId }) => {
      // The last one is the active one
      state.authProvider = providerId
      // Can't change email if SSO is used
      if (!~['emailLink', 'password'].indexOf(providerId)) {
        state.canChangeEmail = false
      }
      // Can only change password if there's a password provider
      if (providerId === 'password') {
        state.hasPassword = true
      }
    })
  }
}
