import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './authentication'
import plan from './plan'
import app from './app'
import currencies from './currencies'
import categories from './categories'
import calculator from './calculator'
import entries from './entries'
import scenarios from './scenarios'
import goals from './goals'

Vue.use(Vuex)

/* If you don't know about Vuex, please refer to https://vuex.vuejs.org/ */

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    authentication,
    plan,
    app,
    currencies,
    categories,
    calculator,
    entries,
    scenarios,
    goals
  }
})
